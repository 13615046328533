@import 'styles/scss/_base';

.root {
  width: 100%;
  display: flex;
  align-items: center;

  @media screen and (min-width: $breakpoint_xxl) {
    &.sidebar {
      flex-direction: column;
      align-items: flex-start;

      .imageWrapper {
        margin-right: 0;
        margin-bottom: 30px;
      }

      .title,
      .link {
        @include d-t5-bold;
      }

      .link {
        color: $accent_colour;
      }
    }
  }
}

.imageWrapper {
  margin-right: 15px;
  width: 120px;
  height: 120px;
  flex-shrink: 0;

  @media screen and (min-width: $breakpoint_l) {
    margin-right: 30px;
    width: 160px;
    height: 160px;
  }

  img {
    border-radius: 4px;
  }
}

.body {
  width: 100%;
}

.title,
.link {
  margin: 0;

  @include m-t4-bold;

  @media screen and (min-width: $breakpoint_l) {
    @include d-t3-bold;
  }
}

.title {
  margin: 0;
}

.link {
  color: $accent_colour;
  border: none;
}
