@import 'styles/scss/_base';

.root {
  position: relative;
  margin: 120px 0 60px;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid $black_pearl;

  @media screen and (min-width: $breakpoint_l) {
    margin: 120px 0 120px;
  }
}

.icon {
  position: absolute;
  bottom: 16px;
  color: $black_pearl;
  width: 32px;
  height: 32px;
}

.input {
  padding: 0 0 0 47px;
  border: 0;
  outline: none;
  width: 100%;
  height: 60px;
  background-color: transparent;

  @include m-t3-bold($black_pearl);
  @include line_height(26px);

  &::placeholder {
    @include font_size(42px);
    color: $storm_grey;

    @media screen and (min-width: $breakpoint_l) {
      @include font_size(60px);
    }
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  @media screen and (min-width: $breakpoint_l) {
    @include d-t2-bold($black_pearl);
  }

  @media screen and (min-width: $breakpoint_xxl) {
    @include d-t1-bold($black_pearl);
  }
}
