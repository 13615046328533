@import 'styles/scss/_base';

.root {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}

.main {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

.header {
  // header height = 60px/90px;
  position: relative;
  width: 100vw;
  top: -60px;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-bottom: -60px;
  background: $black_pearl;

  @media screen and (min-width: $breakpoint_xs) {
    top: -90px;
    margin-bottom: -90px;
  }
}

.headerEntry {
  position: relative;
  padding: 180px 15px 30px;
  width: 100%;
  min-height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  background: $black_pearl;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media screen and (min-width: $breakpoint_l) {
    padding: 210px 60px 60px;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    padding: 210px 120px 60px;
  }
}

.title {
  margin: 0 0 15px;

  @include m-t1-bold($white);

  @media screen and (min-width: $breakpoint_l) {
    margin: 0 0 30px;

    @include d-t1-bold($white);
  }
}

.description {
  margin: 0;

  @include m-t4-regular($white);

  @media screen and (min-width: $breakpoint_l) {
    @include d-t4-regular($white);
  }
}
