@import 'styles/scss/_base';

.root {
  display: block;
}

.topBorder {
  width: 120px;
  height: 15px;
  background: $accent_colour;
}

.title {
  margin: 15px 0 0;

  @include m-t3-bold;

  @media screen and (min-width: $breakpoint_l) {
    margin: 30px 0 0;

    @include d-t3-bold;
  }
}

.text {
  margin: 15px 0 0;
  font-family: $font_inter;
  font-weight: $regular_font_weight;

  @include font_size(16px);
  @include line_height(24px);

  @media screen and (min-width: $breakpoint_l) {
    margin: 30px 0 0;

    @include font_size(20px);
    @include line_height(30px);
  }
}
