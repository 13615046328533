// Soehne
@font-face {
  font-family: 'Sohne Mono Buch';
  src: url('https://cdn.landr.com/fonts/Soehne/soehne-mono-web-buch.woff2')
    format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// SharpGrotesk
@font-face {
  font-family: 'SharpGrotesk Book 21';
  src: url('https://cdn.landr.com/fonts/SharpGrotesk/SharpGroteskBook21.woff2')
      format('woff2'),
    url('https://cdn.landr.com/fonts/SharpGrotesk/SharpGroteskBook21.woff')
      format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SharpGrotesk SemiBold 21';
  src: url('https://cdn.landr.com/fonts/SharpGrotesk/SharpGroteskSmBold21.woff2')
      format('woff2'),
    url('https://cdn.landr.com/fonts/SharpGrotesk/SharpGroteskSmBold21.woff')
      format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SharpGrotesk SemiBold 17';
  src: url('https://cdn.landr.com/fonts/SharpGrotesk/SharpGroteskSmBold17.woff2')
      format('woff2'),
    url('https://cdn.landr.com/fonts/SharpGrotesk/SharpGroteskSmBold17.woff')
      format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
