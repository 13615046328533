@import 'styles/scss/_base';

.root,
.link {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;

  &.withBackground {
    background-color: $grey;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    .mediaWrapper {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }

  a {
    text-decoration: none;
  }
}

.link {
  border: none;
}

.linkWrapper {
  &.withBackground {
    padding: 0 15px 60px;
  }
}

.title {
  display: block;
  margin: 15px 0 0;
  white-space: break-spaces;

  @include m-t4-regular;

  @media screen and (min-width: $breakpoint_l) {
    @include d-t4-regular;
  }

  // TODO: add hover effect
  &:hover {
    opacity: 0.7;
  }
}

.subtitle {
  margin-top: 15px;
  display: block;

  @include d-t8-mono;
}

.label {
  position: absolute;
  left: 15px;
  bottom: 15px;
  z-index: 1;
  border: none;
}

.mediaWrapper {
  @include sixteen-to-nine-aspect-ratio-box;
}

.mediaItem {
  @include aspect-ratio-box-inside;
}
