@import 'styles/scss/_base';

.root {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;

  @media screen and (min-width: $breakpoint_l) {
    padding: 0 60px;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    padding: 0 120px;
  }
}

.main {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  padding-top: 60px;
}

.posts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 0 30px;

  > article {
    margin: 0 0 30px;

    width: 100%;
  }

  @media screen and (min-width: $breakpoint_l) {
    margin: 0 -15px 60px;

    > article {
      margin: 0 15px 60px;

      width: calc(33.333% - 30px);
    }
  }
}

.pager {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  width: 100%;
  align-items: center;
}

.page {
  border-radius: 2px;
  border: 2px $black_pearl solid;
  @include d-t4-bold($black_pearl);
  min-width: 40px;
  padding: 0 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition-duration: 300ms;
  &:hover {
    background: $black_pearl;
    color: $white;
  }
  &.active {
    background: $black_pearl;
    color: $white;
    pointer-events: none;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.25;
  }
  &:not(:last-child) {
    margin-right: 10px;
    @media screen and (max-width: $breakpoint_xs) {
      margin-right: 5px;
    }
  }
  &:first-child,
  &:nth-last-child(2) {
    margin-right: 20px;
    @media screen and (max-width: $breakpoint_xs) {
      margin-right: 15px;
    }
  }
}
