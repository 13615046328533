@import 'styles/scss/_base';

.root {
  position: relative;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $breakpoint_l) {
    flex-direction: row;
  }
}

.body {
  position: relative;
  padding: 30px 15px;
  width: 100%;
  z-index: 2;

  @media screen and (min-width: $breakpoint_l) {
    padding: 30px;
  }
}

.title {
  margin: 0 0 15px;

  @include m-t3-regular($white);

  @media screen and (min-width: $breakpoint_l) {
    margin: 0 0 30px;

    @include d-t3-regular($white);
  }
}

.subtitle {
  @include m-t3-bold($white);

  @media screen and (min-width: $breakpoint_l) {
    @include d-t3-bold($white);
  }
}

.description {
  margin: 0 0 15px;
  font-family: $font_inter;
  font-weight: $regular_font_weight;
  letter-spacing: 0.005em;
  color: $white;

  @include font_size(14px);
  @include line_height(19.6px);

  @media screen and (min-width: $breakpoint_l) {
    margin: 0 0 30px;
    width: calc(50% - 30px);
  }
}

.link {
  text-decoration: none;
}

.cta {
  display: block;
  border: none;
  flex-shrink: 0;

  @media screen and (min-width: $breakpoint_l) {
    width: calc(50% - 30px);
  }
}

.imageBg {
  width: 100%;

  @media screen and (min-width: $breakpoint_l) {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
  }
}
