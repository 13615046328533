@import 'styles/scss/_base';

.root {
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  background: $accent_colour;

  @media screen and (min-width: $breakpoint_m) {
    padding: 30px;
    flex-direction: row;
    align-items: center;
  }
}

.imageWrapper {
  margin-bottom: 15px;
  width: 155px;
  height: 155px;
  flex-shrink: 0;

  @media screen and (min-width: $breakpoint_m) {
    margin-bottom: 0;
    width: 132px;
    height: 132px;
  }

  img {
    border-radius: 4px;
  }
}

.body {
  width: 100%;
}

.title {
  margin: 0 0 15px;

  @include m-t3-regular;

  @media screen and (min-width: $breakpoint_m) {
    margin: 0 30px 30px 0;

    @include d-t3-regular;
  }
}

.subtitle {
  @include m-t3-bold;

  @media screen and (min-width: $breakpoint_m) {
    @include d-t3-bold;
  }
}

.link {
  border: none;
}
