@import 'styles/scss/_base';

.root {
  @extend %button-reset;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: $black_pearl;
  border-radius: 50%;

  &.size- {
    &small {
      width: 30px;
      height: 30px;

      .icon {
        left: 11.3px;
        height: 10.91px;
        width: 10.91px;
      }
    }

    &medium,
    &large {
      width: 60px;
      height: 60px;

      .icon {
        left: 22.6px;
        height: 21.82px;
        width: 21.82px;
      }
    }

    &large {
      @media screen and (min-width: $breakpoint_l) {
        width: 90px;
        height: 90px;

        .icon {
          left: 33.9px;
          height: 32.73px;
          width: 32.73px;
        }
      }
    }
  }

  &:hover,
  &:focus,
  &:active {
    opacity: 0.8;
  }
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: $grey;
}
