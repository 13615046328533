@import 'styles/scss/_base';

.root {
  padding: 5px 15px;
  border-radius: 30px;
  border: none;
  text-decoration: none;

  &.bg- {
    &black {
      background: $black_pearl;
    }

    &white {
      background: $white;
    }
  }
}

.label {
  display: inherit;
  color: $white;
  color: $accent_colour;
  font-family: $font_inter;
  font-weight: $bold_font_weight;

  @each $name, $value in $accent_colours {
    &.accent-color-#{$name} {
      color: $value;
    }
  }

  @each $name, $value in $core_colours {
    &.accent-color-#{$name} {
      color: $value;
    }
  }

  @include font_size(13px);
  @include line_height(20px);
}
