@import "styles/scss/_base";

.root {
  width: 100%;
  height: 100%;

  .caption {
    margin: 15px 0 0;

    @include d-t8-mono($land_blue);
  }
}
