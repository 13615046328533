@import 'styles/scss/_base';

.root {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;

  @media screen and (min-width: $breakpoint_l) {
    padding: 0 60px;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    padding: 0 120px;
  }
}

.main {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

.actionsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 30px;
  }
}

.filterButtons {
  display: flex;
  align-items: center;
}

.filterButton {
  position: relative;

  @extend %button-reset;

  &:hover {
    opacity: 0.7;
  }

  &.active {
    &::before {
      position: absolute;
      content: '';
      bottom: -4px;
      height: 2px;
      width: 100%;
      background: $black_pearl;
    }
  }

  &:not(:last-child) {
    margin-right: 14px;
  }
}

.resultsLabel {
  @include m-t4-bold;

  @media screen and (min-width: $breakpoint_l) {
    @include d-t4-bold;
  }
}

.posts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 0 30px;

  > article {
    margin: 0 0 30px;

    width: 100%;
  }

  @media screen and (min-width: $breakpoint_l) {
    margin: 0 -15px 60px;

    > article {
      margin: 0 15px 60px;

      width: calc(33.333% - 30px);
    }
  }
}

.loadRoot {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.loadLabel {
  margin-bottom: 14px;

  @include d-t7-bold;
}

.loadProgress {
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  max-width: 380px;
  background: rgb(0, 0, 0, 0.1);
  height: 1px;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 60px;
  }
}

.loadProgressBar {
  position: absolute;
  height: 100%;
  background: #000000;
  top: 0;
  left: 0;
}

.loadButton {
  margin-bottom: 30px;
  padding: 10px 35px;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 60px;
  }
}
