@import 'styles/scss/_base';

.root {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow: hidden;
}

.title {
  margin: 0;

  @include m-t2-bold;

  @media screen and (min-width: $breakpoint_l) {
    @include d-t2-bold;
  }
}

.subtitle {
  margin: 15px 0 0;

  @include d-t6-regular;
}

.wrapper {
  max-width: $breakpoint_xxl;
  margin: 0 auto;
}

.listWrapper {
  padding: 30px 15px 60px;

  &.carouselLayout {
    padding: 30px 15px;

    @media screen and (min-width: $breakpoint_l) {
      padding: 60px;
    }

    @media screen and (min-width: $breakpoint_xxl) {
      padding: 60px 120px;
    }
  }

  &.withoutHeading {
    padding: 60px 15px 60px;

    @media screen and (min-width: $breakpoint_l) {
      padding: 60px;
    }

    @media screen and (min-width: $breakpoint_xxl) {
      padding: 60px 120px;
    }
  }

  @media screen and (min-width: $breakpoint_l) {
    padding: 60px;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    padding: 60px 120px;
  }
}

.cardListWrapper {
  margin-top: 30px;

  &.withoutHeading {
    margin-top: 0;
  }

  &.carouselLayout {
    overflow: hidden;
    padding-right: 15px;
    width: calc(100vw - 15px);

    @media screen and (min-width: $breakpoint_l) {
      width: calc(100vw - 60px);
    }

    @media screen and (min-width: $breakpoint_xxl) {
      width: calc(100vw - 120px);
      max-width: calc(#{$breakpoint_xxl} - 120px);
    }
  }
}

.cardsList {
  display: flex;
  flex-direction: column;
  align-items: center;

  > article {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &.carousel {
    margin: 0 -15px;
    flex-direction: row;
    align-items: initial;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include ie-only {
      -ms-overflow-style: auto;
    }

    > article {
      margin: 0 15px;
      max-width: 300px;
      width: 100%;

      &:not(:first-child),
      &:not(:last-child) {
        margin: 0 7.5px;
      }

      &:first-child {
        margin: 0 7.5px 0 15px;
      }

      &:last-child {
        margin: 0 15px 0 7.5px;
      }
    }
  }

  @media screen and (min-width: $breakpoint_m) {
    margin: 0 -15px;
    flex-direction: row;
    align-items: initial;

    > article {
      margin: 0 15px;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    &.twoUp {
      > article {
        width: calc(50% - 30px);
      }
    }

    &.threeUp {
      > article {
        width: calc(33.333% - 30px);
      }
    }

    &.carousel {
      > article {
        max-width: 380px;

        &:not(:first-child),
        &:not(:last-child),
        &:first-child,
        &:last-child {
          margin: 0 15px;
        }
      }
    }
  }
}

.overflowSpaceFix {
  width: 7.5px;
  flex-shrink: 0;

  @media screen and (min-width: $breakpoint_m) {
    width: 15px;
  }
}
