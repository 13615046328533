@import "styles/scss/_base";

.root {
  width: 100%;
  display: flex;
  flex-direction: column;

  .caption {
    margin: 15px 0 0;

    @include d-t8-mono($land_blue);
  }
}

.player {
  padding: 10px 20px 10px 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: $black_pearl;
  user-select: none;
  border-radius: 10px;

  &.withImage {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
