@import "styles/scss/_base";

.root {
  @extend %button-reset;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  flex-shrink: 0;

  &:hover {
    opacity: 0.9;
  }
}

.icon {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}
