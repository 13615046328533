@import 'styles/scss/_base';

.root {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 4px;
}

.button {
  position: absolute;
  left: 15px;
  bottom: 15px;
  z-index: 2;
  width: 30px;
  height: 30px;

  @extend %button-reset;

  &:hover {
    opacity: 0.8;
  }
}
