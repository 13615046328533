@import 'styles/scss/_base';

.root {
  border: none;
  display: block;

  @include one-to-one-aspect-ratio-box;

  @media screen and (min-width: $breakpoint_m) {
    @include sixteen-to-nine-aspect-ratio-box;
  }
}

.entry {
  @include aspect-ratio-box-inside;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(1, 1, 1, 0.2);
}

.details {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.label {
  margin-bottom: 15px;
  border: none;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 30px;
  }
}

.linkTitle {
  border: none;
}

.title {
  margin: 0;
  max-width: 1140px;
  text-align: center;

  @include m-t1-bold($white);

  @media screen and (min-width: $breakpoint_l) {
    @include d-t1-bold($white);
  }

  &:hover {
    opacity: 0.9;
  }
}
