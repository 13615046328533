@import "styles/scss/_base";

.root {
  @extend %button-reset;

  @include d-t7-bold($white);

  @include line_height(20px);

  position: relative;
  padding: 10px 30px 10px 30px;
  background: $black_pearl;
  border-radius: 2px;
  color: $white;
  flex-shrink: 0;

  &.loading {
    cursor: default;
  }

  &:hover,
  &:focus,
  &:active {
    opacity: 0.9;
  }

  &[disabled] {
    color: $blue_grey;
    cursor: not-allowed;
  }
}

.label {
  display: inherit;

  &.hidden {
    visibility: hidden;
  }
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  animation: loading 1s linear infinite;
  position: relative;
  left: -8px;
}

@keyframes loading {
  0% {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 8px 0px 0px 0px rgba(255, 255, 255, 0.2), 16px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }

  25% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 8px 0px 0px 0px rgba(255, 255, 255, 2), 16px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }

  75% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 8px 0px 0px 0px rgba(255, 255, 255, 0.2), 16px 0px 0px 0px rgba(255, 255, 255, 1);
  }

  100% {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 8px 0px 0px 0px rgba(255, 255, 255, 0.2), 16px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }
}
