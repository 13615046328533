@import 'styles/scss/_base';

.root {
  &.position- {
    &inline {
      width: 100%;
      margin: 30px 0;

      @media screen and (min-width: $breakpoint_l) {
        margin: 60px 0;
      }
    }

    &large {
      margin-top: 30px;
      margin-bottom: 30px;
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;

      > div {
        max-width: $breakpoint_xxl;
        margin: 0 auto;

        p {
          max-width: 650px;
          margin: 15px auto 0;

          @media screen and (max-width: 680px) {
            margin: 15px 0 0;
            padding: 0 15px;
          }
        }
      }

      @media screen and (min-width: $breakpoint_l) {
        margin-top: 60px;
        margin-bottom: 60px;

        > div {
          padding: 0 30px;
        }
      }
    }

    &left-side-bar,
    &right-side-bar {
      width: 100%;
      margin: 30px 0;

      @media screen and (min-width: $breakpoint_l) {
        margin: 60px 0;
      }

      @media screen and (min-width: $breakpoint_xxl) {
        position: absolute;
        margin: 0;
        width: 100%;
        max-width: 275px;
      }
    }

    &left-side-bar {
      @media screen and (min-width: $breakpoint_xxl) {
        left: -335px;
      }
    }

    &right-side-bar {
      @media screen and (min-width: $breakpoint_xxl) {
        right: -335px;
      }
    }
  }
}
