@import 'styles/scss/_base';

.root {
  padding: 30px;
  display: flex;
  flex-direction: column;
  background: $land_blue;
  border-radius: 10px;
  border: none;
  flex-shrink: 0;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
}

.imageWrapper {
  margin-bottom: 43px;
  width: 80px;
  height: 80px;
}

.title {
  margin: 0 0 15px;

  @include d-t3-bold($white);
}

.description {
  margin: 0;

  @include d-t6-regular($white);
}
