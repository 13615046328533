@import 'styles/scss/_base';

.root {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;

  @media screen and (min-width: $breakpoint_l) {
    padding: 0 30px;
  }
}

.main {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

.header {
  height: 100vh;
  // header height = 60px/90px;
  position: relative;
  width: 100vw;
  top: -60px;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-bottom: -60px;
  background: $accent_colour;

  @media screen and (min-width: $breakpoint_xs) {
    top: -90px;
    margin-bottom: -90px;
  }
}

.readMore {
    @media screen and (min-width: $breakpoint_l) {
        padding-left: 30px;
    }

    @media screen and (min-width: $breakpoint_xxl) {
        padding-left: 90px;
    }
}

.headerEntry {
  padding: 0 30px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;

  @media screen and (min-width: $breakpoint_l) {
    padding: 0 110px 0;
  }
}

.bgImagesWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  transform: translate(-50%, -50%);
  mix-blend-mode: multiply;
}

.mediaWrapper {
  @include sixteen-to-nine-aspect-ratio-box;
}

.mediaItem {
  @include aspect-ratio-box-inside;
}

.bgImage {
  position: absolute;
  width: 100%;
  filter: grayscale(100%);

  &:nth-child(1) {
    max-width: 255px;
    top: 0;
    right: 0;

    @media screen and (min-width: $breakpoint_m) {
      max-width: 380px;
      left: 30%;
    }

    @media screen and (min-width: $breakpoint_xxl) {
      left: 21%;
    }
  }

  &:nth-child(2) {
    max-width: 196px;
    left: 0;
    top: 24%;

    @media screen and (min-width: $breakpoint_m) {
      max-width: 380px;
      right: 0;
      left: auto;
      top: 20%;
    }

    @media screen and (min-width: $breakpoint_xxl) {
      top: 11%;
      right: 10%;
    }
  }

  &:nth-child(3) {
    max-width: 256px;
    top: 48%;
    right: 0;

    @media screen and (min-width: $breakpoint_m) {
      max-width: 512px;
      left: 0;
      right: auto;
      top: 36%;
    }

    @media screen and (min-width: $breakpoint_xxl) {
      max-width: 480px;
      bottom: 18%;
      top: auto;
    }
  }

  &:nth-child(4) {
    max-width: 375px;
    bottom: 0;

    @media screen and (min-width: $breakpoint_m) {
      max-width: 484px;
      right: 0;
      left: auto;
    }

    @media screen and (min-width: $breakpoint_xxl) {
      max-width: 720px;
    }
  }
}

.title {
  margin: 0;
  text-align: center;

  @include m-t1-bold($white);

  @media screen and (min-width: $breakpoint_l) {
    @include d-t1-bold($white);
  }
}

.description {
  padding: 60px 15px;
  max-width: 960px;
  margin: 0 auto;

  @include m-t3-regular($land_blue);

  @media screen and (min-width: $breakpoint_l) {
    padding: 120px 0;

    @include d-t3-regular($land_blue);
  }

  p {
    margin: 0 auto;

    @include m-t3-regular($land_blue);

    @media screen and (min-width: $breakpoint_l) {
      @include d-t3-regular($land_blue);
    }
  }
}

.scrollBottomButton {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  bottom: 36px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;

  @extend %button-reset;

  @media screen and (min-width: $breakpoint_l) {
    bottom: 30px;
  }
}
