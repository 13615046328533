@import 'styles/scss/_base';

.root {
  padding: 15px;
  background: $accent_colour;

  @media screen and (min-width: $breakpoint_l) {
    padding: 30px;
  }

  .title {
    margin: 0 0 15px;

    @include m-t5-bold($land_blue);

    @media screen and (min-width: $breakpoint_l) {
      margin: 0 0 30px;

      @include d-t5-bold($land_blue);
    }
  }

  .content {
    margin: 0;

    @include d-t8-mono($land_blue);

    a {
      text-decoration-color: $land_blue;
    }

    p {
      &:last-of-type {
        margin: 0;
      }
    }

    ol {
      @include d-t8-mono($land_blue);

      a[href] {
        text-decoration-color: $land_blue;
      }
    }
  }
}
