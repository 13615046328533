@import 'styles/scss/_base';

.root {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.inputContainer {
  width: 100%;
}

.input {
  padding: 13px 15px 10px 15px;
  border: 0;
  outline: none;
  width: 100%;
  background-color: $grey;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;

  @include d-t7-regular($black_pearl);

  @include line_height(17px);

  &::placeholder {
    color: $black_pearl;
  }
}

.button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.successMessage {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 13px 15px 10px 15px;
  background-color: $white;

  @include d-t7-regular($black_pearl);

  @include line_height(17px);
}
