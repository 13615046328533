@import 'styles/scss/_base';

.root {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.wrapper {
  max-width: $breakpoint_xxl;
  margin: 0 auto;
  padding: 30px 15px;

  @media screen and (min-width: $breakpoint_l) {
    padding: 60px;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    padding: 60px 120px;
  }
}

.entry {
  display: flex;
  flex-direction: column;
  background: $baby_blue;
  padding: 30px 15px;

  @media screen and (min-width: $breakpoint_l) {
    flex-direction: row;
    padding: 0;
  }
}

.ctaRoot {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: $breakpoint_l) {
    padding: 60px;
    width: 60%;
  }
}

.teaserRoot {
  display: block;
  border: none;
  margin-top: 15px;
  width: 100%;
  text-decoration: none;

  @media screen and (min-width: $breakpoint_l) {
    margin-top: 0;
    width: 40%;
  }
}

.title {
  margin: 0 0 15px;

  @include m-t2-bold;

  @media screen and (min-width: $breakpoint_l) {
    margin: 0 0 30px;

    @include d-t2-bold;
  }
}

.description {
  margin: 0 0 15px;
  font-family: $font_inter;
  color: $black_pearl;

  @include font_size(18px);
  @include line_height(27px);

  @media screen and (min-width: $breakpoint_l) {
    margin: 0 0 30px;
  }
}

.mediaWrapper {
  @include sixteen-to-nine-aspect-ratio-box;
}

.mediaItem {
  @include aspect-ratio-box-inside;
}

.postTitle {
  margin-top: 15px;
  margin-bottom: 15px;

  @include m-t4-regular;

  @media screen and (min-width: $breakpoint_l) {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: 10px;

    @include d-t4-regular;
  }
}
