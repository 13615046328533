@import 'styles/scss/_base';

.root {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow: hidden;
}

.title {
  margin: 0;

  @include m-t2-bold;

  @media screen and (min-width: $breakpoint_l) {
    @include d-t2-bold;
  }
}

.subtitle {
  @include m-t2-regular;

  @media screen and (min-width: $breakpoint_l) {
    @include d-t2-regular;
  }
}

.wrapper {
  max-width: $breakpoint_xxl;
  margin: 0 auto;
}

.listWrapper {
  padding: 30px 15px;

  @media screen and (min-width: $breakpoint_l) {
    padding: 60px;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    padding: 60px 120px;
  }
}

.cardListWrapper {
  margin-top: 30px;
  overflow: hidden;
  padding-right: 15px;
  width: calc(100vw - 15px);

  @media screen and (min-width: $breakpoint_l) {
    padding-right: 0;
    width: 100%;
    margin-top: 60px;
  }
}

.cardsList {
  display: flex;
  flex-direction: row;
  align-items: initial;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;
  margin: 0 -15px;

  &::-webkit-scrollbar {
    display: none;
  }

  > a {
    width: 100%;
    max-width: 300px;
    margin-bottom: 15px;

    &:not(:first-child),
    &:not(:last-child) {
      margin: 0 7.5px;
    }

    &:first-child {
      margin: 0 7.5px 0 15px;
    }

    &:last-child {
      margin: 0 15px 0 7.5px;
    }
  }

  @media screen and (min-width: $breakpoint_l) {
    flex-wrap: wrap;
    overflow: hidden;
    margin: 0 -7.5px -15px;

    > a {
      margin: 0 7.5px 15px;
      width: calc(33.333% - 15px);
      max-width: 100%;

      &:not(:first-child),
      &:not(:last-child),
      &:first-child,
      &:last-child {
        margin: 0 7.5px 15px;
      }
    }
  }

  @media screen and (min-width: $breakpoint_xxl) {
    > a {
      width: calc(25% - 15px);
    }
  }
}

.overflowSpaceFix {
  width: 7.5px;
  flex-shrink: 0;

  @media screen and (min-width: $breakpoint_l) {
    display: none;
  }
}
