@import 'styles/scss/_base';

.root {
  position: fixed;
  left: 15px;
  right: 15px;
  right: calc(15px + var(--scroll-bar));
  bottom: 15px;
  background: #f1f1f1;
  border-radius: 15px;
  z-index: 10;
  outline: none;

  @media screen and (min-width: $breakpoint_l) {
    position: fixed;
    right: 30px;
    bottom: 28px;
    left: auto;
    width: 100%;
    max-width: 275px;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    position: absolute;
    height: auto;
    top: auto;
    right: -335px;
    width: 100%;
    max-width: 275px;
    bottom: auto;
  }

  .link {
    border: none;
  }

  &.open {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    z-index: 99;
    overflow: auto;

    @media screen and (min-width: $breakpoint_l) {
      position: fixed;
      right: 0;
      left: auto;
      bottom: auto;
      height: 100%;
      width: 100%;
      max-width: 410px;
    }

    @media screen and (min-width: $breakpoint_xxl) {
      position: absolute;
      height: auto;
      top: auto;
      right: -335px;
      width: 100%;
      max-width: 275px;
      border-radius: 15px;
      bottom: auto;
    }

    .entry {
      padding: 30px;

      @media screen and (min-width: $breakpoint_l) {
        padding: 60px;
      }

      @media screen and (min-width: $breakpoint_xxl) {
        padding: 30px;
      }
    }

    .title {
      margin-bottom: 16px;
      max-width: 200px;

      @media screen and (min-width: $breakpoint_xxl) {
        margin-bottom: 0;
      }
    }

    .description {
      display: none;
    }

    .titleWrapper {
      margin-bottom: 30px;

      @media screen and (min-width: $breakpoint_xxl) {
        margin-bottom: 16px;
      }
    }

    .toggleButton {
      display: none;
    }

    .nav {
      display: block;
    }

    .bookmarkIcon {
      display: block;
    }
  }
}

.closeButton {
  position: absolute;
  right: 15px;
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  @extend %button-reset;
}

.closeIcon {
  width: 67px;
  height: 67px;
  flex-shrink: 0;
  color: $black_pearl;
}

.entry {
  position: relative;
  padding: 14px 16px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $breakpoint_l) {
    padding: 30px;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 16px;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.bookmarkIcon {
  display: none;
  flex-shrink: 0;

  @media screen and (min-width: $breakpoint_xxl) {
    margin-left: 12px;
  }
}

.title {
  margin: 0;
  max-width: 100%;

  @include m-t4-bold;

  @media screen and (min-width: $breakpoint_l) {
    max-width: 200px;

    @include d-t4-bold;
  }
}

.description {
  margin-bottom: 19px;
  display: none;

  @include d-t7-regular;

  a {
    border-color: $black_pearl;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    display: block;
  }
}

.toggleButton {
  @extend %button-reset;

  text-align: left;
  color: $black_pearl;
}

.nav {
  display: none;
  margin: 0 -30px;

  @media screen and (min-width: $breakpoint_l) {
    margin: 0 -60px;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    margin: 0 -30px;
  }
}

.list {
  margin: 0;
  width: 100%;
}

.menuItem {
  display: flex;

  &.active {
    background: $white;

    .link {
      color: $accent_colour;
      padding: 15px 30px;
      margin: 0;

      @media screen and (min-width: $breakpoint_l) {
        padding: 15px 60px;
      }

      @media screen and (min-width: $breakpoint_xxl) {
        padding: 15px 30px;
      }
    }
  }

  &:first-child {
    .link {
      margin-top: 0;
    }
  }

  &:last-child {
    .link {
      margin-bottom: 0;
    }
  }
}

.link {
  display: flex;
  border: none;
  width: 100%;
  margin: 15px 30px;

  @include d-t7-regular;

  @media screen and (min-width: $breakpoint_l) {
    margin: 15px 60px;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    margin: 15px 30px;
  }

  &:hover {
    color: $accent_colour;
  }
}

.linkOrderNumber {
  margin-right: 14px;
}
