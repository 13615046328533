@import 'styles/scss/_base';

.root {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.wrapper {
  position: relative;
  max-width: $breakpoint_xxl;
  margin: 0 auto;
}

.sizeWrapper {
  padding: 30px 0;

  @media screen and (min-width: $breakpoint_l) {
    padding: 60px 30px;
  }
}
