@import 'styles/scss/_base';

.root {
  display: block;

  &.boxed {
    padding: 0 15px 15px;
    background: $accent_colour;

    @media screen and (min-width: $breakpoint_l) {
      padding: 0 30px 30px;
    }

    .topBorder {
      display: none;
    }

    .title {
      @include m-t3-regular;

      @media screen and (min-width: $breakpoint_l) {
        @include d-t3-regular;
      }
    }

    .subtitle {
      color: $land_blue;
    }
  }
}

.topBorder {
  width: 100%;
  height: 15px;
  background: $black_pearl;
}

.body {
  padding: 15px 0;

  @media screen and (min-width: $breakpoint_l) {
    padding: 30px 0;
  }
}

.title,
.subtitle {
  margin: 0;

  @include m-t3-bold;

  @media screen and (min-width: $breakpoint_l) {
    @include d-t3-bold;
  }
}

.title {
  color: $black_pearl;
}

.subtitle {
  color: $land_blue;
}
