@import 'styles/scss/_base';

.root {
  display: block;
}

.topBorder {
  width: 100%;
  height: 15px;
  background: $accent_colour;
}

.body {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $breakpoint_l) {
    margin-top: 30px;
    flex-direction: row;
    justify-content: space-between;
  }
}

.title {
  margin: 15px 0;

  @include m-t3-bold;

  @media screen and (min-width: $breakpoint_l) {
    margin: 0 100px 0 0;

    @include d-t3-bold;
  }
}

.link {
  border: none;
  flex-shrink: 0;
}
