@import 'styles/scss/_base';

.root {
  display: flex;
  flex-direction: column;
  margin: 40px 0;
}

.shareArticle {
  margin-bottom: 40px;
}

.authorBioWrapper {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $breakpoint_l) {
    flex-direction: row;
    align-items: center;
  }

  .description,
  .authorName {
    font-family: $font_inter;
    font-weight: $regular_font_weight;

    @include font_size(13px);
    @include line_height(18.2px);
  }

  .description {
    margin-bottom: 16px;
  }

  .authorName {
    border-bottom: 1px solid $black_pearl;
  }
}

.avatarWrapper {
  display: flex;
  margin-bottom: 15px;
  flex-shrink: 0;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 0;
    margin-right: 15px;
  }

  > div {
    flex-shrink: 0;
    border-radius: 50%;
  }
}
