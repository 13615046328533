@import 'styles/scss/_base';

.root {
  display: flex;
  width: 100%;

  &:first-child {
    margin-bottom: 60px;
  }

  @media screen and (min-width: $breakpoint_l) {
    width: calc(50% - 15px);

    &:first-child {
      margin-bottom: 0;
    }
  }
}

.link {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  text-decoration: none;

  @media screen and (min-width: $breakpoint_l) {
    justify-content: space-between;
  }
}

.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 30px 15px 60px;

  @media screen and (min-width: $breakpoint_l) {
    align-items: center;
    margin: 60px 30px;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    margin: 60px;
  }
}

.label {
  margin-bottom: 15px;

  @media screen and (min-width: $breakpoint_xxl) {
    margin-bottom: 30px;
  }
}

.title {
  margin: 0;

  @include m-t2-bold;

  @media screen and (min-width: $breakpoint_l) {
    text-align: center;

    @include d-t2-bold;
  }

  &:hover {
    opacity: 0.8;
  }
}

.mediaRoot {
  padding: 0 15px 15px;
  flex-shrink: 0;
  width: 100%;

  &.fullSize {
    padding: 0;
  }

  @media screen and (min-width: $breakpoint_l) {
    padding: 30px;
  }
}

.mediaWrapper {
  @include sixteen-to-nine-aspect-ratio-box;
}

.mediaItem {
  @include aspect-ratio-box-inside;
}
