@import 'styles/scss/_base';

.root {
  width: 100%;
  height: 100%;

  .caption {
    margin: 15px 0 0;

    @include d-t8-mono($land_blue);
  }
}

.player {
  position: relative;
  height: 100%;
  width: 100%;
  background: $black_pearl;

  iframe {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
