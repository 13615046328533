@import 'styles/scss/_base';

.root {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;

  @media screen and (min-width: $breakpoint_l) {
    padding: 0 30px;
  }
}

.main {
  max-width: 650px;
  margin: 0 auto;
  position: relative;
}

.header {
  // header height = 60px/90px;
  position: relative;
  padding: 142px 15px 30px;
  width: 100vw;
  top: -60px;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-bottom: -60px;
  background: $accent_colour;

  @media screen and (min-width: $breakpoint_xs) {
    top: -90px;
    margin-bottom: -90px;
  }

  @media screen and (min-width: $breakpoint_l) {
    padding: 142px 60px 30px;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    padding: 90px 160px 30px;
  }
}

.headerEntry {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
  max-width: 1120px;
  margin: 0 auto;
}

.categories {
  margin-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > a {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.title {
  margin: 12px 0 12px;
  text-align: center;
  max-width: 100%;

  @include m-t1-bold;

  @media screen and (min-width: $breakpoint_l) {
    @include d-t1-bold;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    margin: 30px 0 30px;
  }
}

.meta {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.metaAuthor,
.metaPublishDate,
.metaTimeToRead {
  font-family: $font_inter;
  font-weight: $regular_font_weight;
  color: $land_blue;
  letter-spacing: 0.01em;

  @include font_size(13px);
  @include line_height(18px);
}

.metaAuthor {
  font-weight: $bold_font_weight;
}

.postMediaWrapper {
  position: relative;
  padding: 0 15px 30px;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 50%;
    background: $accent_colour;
  }

  @media screen and (min-width: $breakpoint_l) {
    padding: 0 60px 30px;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    padding: 0 160px 30px;
  }
}

.postMedia {
  position: relative;
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  height: 100%;
  max-height: 630px;
  // to avoid big LCP blink
  // overflow: hidden;
  // min-height: calc(100vw / 2.309);

  div {
    max-height: 630px;
  }

  &.video {
    > div {
      height: 190px;

      @media screen and (min-width: $breakpoint_2xs) {
        height: 268px;
      }

      @media screen and (min-width: $breakpoint_xs) {
        height: 268px;
      }

      @media screen and (min-width: $breakpoint_s) {
        height: 378px;
      }

      @media screen and (min-width: $breakpoint_m) {
        height: 525px;
      }

      @media screen and (min-width: $breakpoint_l) {
        height: 630px;
      }

      @media screen and (min-width: $breakpoint_xxl) {
        height: 630px;
      }
    }
  }
}

.postShareArticle {
  padding-bottom: 30px;

  @media screen and (min-width: $breakpoint_l) {
    padding-bottom: 45px;
  }
}
