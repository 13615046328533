@import './scss/_fonts';
@import './reset.scss';
@import './scss/_base';

:root {
  --navbar-height: 52px;
  --top-offset: 40px;
  --scroll-offset: calc(var(--navbar-height) + var(--top-offset));
  --font-inter: 'Inter';
}

@supports (font-variation-settings: normal) {
  :root {
    --font-inter: 'InterVariable';
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: $font_inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  height: 100%;
  min-height: 100%;
  position: relative;
  font-size: 1rem;
  line-height: normal;
  font-weight: $regular_font_weight;
  font-style: normal;
  background: $white !important;
}

video {
  width: 100%;
}

body {
  overflow-x: hidden;
}

a {
  color: inherit;
  // Safari fix:
  // Do not use shorthand "text-decoration: underline $accent_colour 1px;"
  // as it will not work on Safari browsers
  text-decoration-line: underline;
  text-decoration-color: $black_pearl;
  text-decoration-thickness: 1px;
  // ----------

  text-underline-position: under;
  max-width: 100%;

  img {
    max-width: 100%;
    height: 100%;
  }
}

* {
  box-sizing: border-box;
}

main {
  display: block;
}

main {
  h1,
  h2 {
    margin: 60px 0 15px;
    scroll-margin-top: var(--scroll-offset);

    a {
      text-decoration-thickness: 2px;
      text-underline-offset: -4px;
    }

    @include m-t2-bold;

    @media screen and (min-width: $breakpoint_l) {
      margin: 120px 0 30px;

      @include d-t2-bold;
    }
  }

  h3 {
    margin: 30px 0 15px;
    scroll-margin-top: var(--scroll-offset);

    a {
      text-decoration-thickness: 2px;
    }

    @include m-t3-bold;

    @media screen and (min-width: $breakpoint_l) {
      margin: 60px 0 30px;

      @include d-t3-bold;
    }
  }

  h4,
  h5,
  h6 {
    margin: 30px 0 15px;

    @include m-t6-bold;

    @media screen and (min-width: $breakpoint_l) {
      margin: 60px 0 30px;

      @include d-t6-bold;
    }
  }

  p a {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;

    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
    }
  }

  p,
  ul,
  ol {
    margin-bottom: 24px;

    @include m-t6-regular;

    @media screen and (min-width: $breakpoint_l) {
      margin-bottom: 30px;

      @include d-t6-regular;
    }
  }

  ul,
  ol {
    list-style: inside;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  blockquote {
    width: 100%;
    margin: 30px 0;
    word-break: break-word;

    p {
      @include d-t5-bold($land_blue);

      margin-bottom: 0;
    }

    @media screen and (min-width: $breakpoint_l) {
      margin: 60px 0;
    }

    @media screen and (min-width: $breakpoint_xxl) {
      position: absolute;
      margin: 0;
      width: 100%;
      max-width: 275px;
      margin-left: -335px;
    }
  }
}
