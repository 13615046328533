@import 'styles/scss/_base';

.root {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $breakpoint_l) {
    flex-direction: row;
    justify-content: space-between;
  }
}
