@import 'styles/scss/_base';

.root {
  position: relative;
  padding-left: 27px;

  @media screen and (min-width: $breakpoint_xxl) {
    &.sidebarPosition {
      padding-left: 0;
      padding-top: 27px;

      &::before {
        height: 12px;
        width: 60px;
      }

      .content {
        @include font_size(24px);
        @include line_height(28.8px);
      }
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: $accent_colour;
    width: 12px;
    content: '';
  }

  @each $name, $value in $accent_colours {
    &.accent-color-#{$name} {
      &::before {
        background: $value;
      }
    }
  }

  @each $name, $value in $core_colours {
    &.accent-color-#{$name} {
      &::before {
        background: $value;
      }
    }
  }

  .content {
    margin: 0;

    @include m-t5-bold($land_blue);

    @media screen and (min-width: $breakpoint_l) {
      @include font_size(22px);
      @include line_height(26.4px);
    }
  }
}
