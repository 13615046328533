@import 'styles/scss/_base';

.root {
  border: none;
}

.mediaWrapper {
  @include sixteen-to-nine-aspect-ratio-box;
}

.mediaItem {
  @include aspect-ratio-box-inside;
}

.title {
  display: block;
  margin: 15px 0 0;
  padding: 0 15px;
  border: none;

  &:hover {
    opacity: 0.9;
  }

  @include m-t2-bold;

  @media screen and (min-width: $breakpoint_l) {
    margin: 30px 0 0;
    padding: 0;

    @include d-t2-bold;
  }
}

.label {
  position: absolute;
  left: 15px;
  bottom: 15px;
  z-index: 1;
  border: none;
}
