@import "styles/scss/_base";

.root {
  position: relative;
  width: 21px;
  height: 20px;
  margin-left: 20px;
  cursor: pointer;
  flex-shrink: 0;
  outline: none;

  &.grabbing {
    cursor: grabbing;
  }

  :global {
    .fill1 {
      box-shadow: inset 1px 0 0 $white;
    }

    .fill2 {
      box-shadow: inset 2px 0 0 $white;
    }

    .fill3 {
      background-color: $white;
    }
  }

  > div {
    width: 3px;
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $blue_grey;
  }

  > div:nth-child(1) {
    height: 6px;
    left: 0;

    &:hover {
      height: 8px;
    }
  }

  > div:nth-child(2) {
    height: 11px;
    left: 6px;

    &:hover {
      height: 13px;
    }
  }

  > div:nth-child(3) {
    height: 16px;
    left: 12px;

    &:hover {
      height: 18px;
    }
  }

  > div:nth-child(4) {
    height: 20px;
    left: 18px;

    &:hover {
      height: 22px;
    }
  }
}
