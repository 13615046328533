@import "styles/scss/_base";

.root {
  --seek-before-width: 0%;
  position: relative;
  -webkit-appearance: none;
  width: 100%;
  margin: 0;
  padding: 10px 0;
  outline: none;
  background-color: transparent;
  cursor: pointer;

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    background: $blue_grey;
  }

  &::before {
    position: absolute;
    content: "";
    top: 10px;
    left: 0;
    width: var(--seek-before-width);
    height: 2px;
    background-color: $white;
    cursor: pointer;
  }

  &::-webkit-slider-thumb {
    position: relative;
    -webkit-appearance: none;
    box-sizing: content-box;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: $white;
    cursor: pointer;
    margin: -4px 0 0 0px;
  }

  &::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: $blue_grey;
  }

  &::-moz-range-progress {
    background-color: $blue_grey;
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-moz-range-thumb {
    box-sizing: content-box;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: $white;
    cursor: pointer;
  }

  &::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: transparent;
    border: solid transparent;
    color: transparent;
  }

  &::-ms-fill-lower {
    background-color: $blue_grey;
  }

  &::-ms-fill-upper {
    background: $blue_grey;
  }

  &::-ms-thumb {
    box-sizing: content-box;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: $white;
    cursor: pointer;
  }
}
